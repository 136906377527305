<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных проекта
      </h4>
      <div class="alert-body">
        Не найдено ни одного проекта с этим идентификатором. Можете перейти в
        <b-link
          class="alert-link"
          :to="{ name: 'projects'}"
        >
          Список проектов
        </b-link>
        и выбрать другой проект.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <b-card>
      <b-row>
        <b-col>
          <h4>
            {{ item.name }}
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <table class="mt-1 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CircleIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">ID</span>
              </th>
              <td class="pb-50">
                {{ item.id }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="HexagonIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Компания</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ company.name ? company.name : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Дата создания</span>
              </th>
              <td class="pb-50">
                {{ item.created_at }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Дата изменения</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ item.updated_at }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-row
        v-if="$store.getters['workingMode/checkPermission']('edit_projects') || $store.getters['workingMode/checkPermission']('delete_projects')"
        class="mt-2"
      >
        <b-col>
          <div class="d-flex flex-wrap">
            <b-button
              v-if="item.id && $store.getters['workingMode/checkPermission']('edit_projects')"
              :to="{ name: 'projects.edit', params: { id: item.id } }"
              variant="primary"
            >
              Изменить
            </b-button>
            <b-button
              v-if="$store.getters['workingMode/checkPermission']('delete_projects')"
              variant="outline-danger"
              class="ml-1"
              @click="deleteItem(item)"
            >
              Delete
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BOverlay, BButton,
} from 'bootstrap-vue'
import { deleteProject, getProject } from '@/services/main-api/business/projects'
import { getCompany } from '@/services/main-api/business/companies'

export default {
  name: 'ShowProjectView',
  components: {
    BCard, BRow, BCol, BOverlay, BButton,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      item: {},
      company: {},
      loading: false,
      getItemError: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      await this.getItem()
      await this.getCompany()
      this.loading = false
    },
    async getItem() {
      try {
        const res = await getProject(this.id)
        this.item = res.data.data
      } catch (e) {
        this.item = {}
        this.getItemError = true
      }
    },
    async getCompany() {
      try {
        const res = await getCompany(this.item.company_id)
        this.company = res.data.data
      } catch (e) {
        this.company = {}
        this.getItemError = true
      }
    },
    async deleteItem(item) {
      const resButton = await this.$swal({
        title: 'Вы уверены?',
        text: `Проект ${item.name} будет удален`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да, удалить!',
        cancelButtonText: 'Отмена',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (resButton.value) {
        try {
          await deleteProject(item.id)
          this.getData()
          this.$swal({
            icon: 'success',
            title: 'Удалено!',
            text: 'Проект был удален.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          await this.$router.push({ name: 'projects' })
          this.$store.dispatch('workingMode/getData')
        } catch (e) {
          console.log(e)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
